// start content

.custom-select {
    background: #F5F5F5;
    border: 1px solid #898F9E;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.8px;
    position: relative;
  }
  .custom-select select {
    display: none !important;
  }
  .select-selected {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.8px;
    color: #4C5366;
  }
  .select-selected:after {
    position: absolute;
    content: "\f078";
    font-family: "fontawesome";
    top: 0px;
    right: 0px;
    color: #ffffff;
    background: #898F9E;
    border-radius: 0px 2px 2px 0;
    padding: 6px 10px;
  }
  .select-selected.select-arrow-active:after {
    content: "\f077";
    font-family: "fontawesome";
  }
  .select-items div {
    padding: 8px 16px;
    cursor: pointer;
  }
  .select-items {
    position: absolute;
    background-color: #fbfbfb;
    top: 97%;
    left: -1%;
    right: 0;
    z-index: 99;
    border: 1px solid #898f9d;
    margin: 0;
    border-radius: 4px;
    width: 200px;
    overflow-y: auto;
    max-height: 900%;
    min-height: 300%;
    height: auto;
    display: none;
  }
  .select-items input {
    width: 90%;
    margin-left:5%;
    margin-top:2%;
    border: none !important;
    font-size: 10px !important;
  }
  .select-items div span {
    color: #007bff;
  }
  .select-hide {
    display: none;
  }
  .select-items div:hover, .select-items div[selected="selected"], .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
  }

.custom-select.phonenumber {
    border-right: 0;
    border-radius: 4px 0px 0px 4px;
    padding: 0 20px 0 7px;
    position: relative;
}
.custom-select.phonenumber::after{
  content: "";
  width: 1px;
  height: 20px;
  background-color:#C4C4C4;
  position: absolute;
  top: 5px;
  right: 0;
}
.custom-select.phonenumber .select-selected:after {
  position: absolute;
  content: "\f078";
  font-family: "fontawesome";
  top: 0px;
  right: 0px;
  color: #898F9E;
  background: transparent;
  border-radius: 0 0 0 0;
  padding: 8px 7px;
  font-size: 10px;
}
.custom-select.phonenumber .select-items div {
  padding: 4px 12px;
  cursor: pointer;
}
.form-control.form-control-phonenumber {
  border-left: 0 !important;
  border-radius: 0px 4px 4px 0 !important;
}